import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/HRandPayroll1.png";
import image2 from "../../assets/images/HRandPayroll2.png";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const HRandPayroll = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>HR and Payroll Management System</title>
        <meta
          name="description"
          content="Revolutionize your HR operations with our advanced payroll management software."
        />
        <meta
          name="keywords"
          content="HR management, payroll software, employee management, HR software, Human Resources software"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            HR And Payroll Management System
          </h1>
        </div>

        <p className="lg:text-lg text-sm  md:text-sm mb-2 text-Cdark font-desc">
          Revolutionize Your HR Operations with Our Advanced Payroll Management
          Software! <br />
          Welcome to DigIT-Hack, where we redefine HR management with our
          feature-rich and intuitive payroll management software. Our
          comprehensive solution is designed to simplify complex processes,
          optimize HR tasks, and empower your workforce .
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <h2 className="text-2xl font-semibold text-start">Key Features</h2>
        <h2 className="text-xl lg:text-2xl font-semibold">
          1. Automated Payroll Processing
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Accurate Calculations: Eliminate errors with automated payroll calculations, including taxes, deductions, and bonuses.  
	Compliance Management: Ensure adherence to local tax regulations and laws with automated compliance checks.

        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
        2.	Customizable Solutions

        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Tailored Configurations: Customize payroll settings based on your company's structure, including multiple payment schedules and deduction types. 
	Scalable Platform: Grow seamlessly with a solution that scales as your business expands. 

        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          3. Compliance and Security

        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Data Security: Ensure the highest level of security with encrypted data storage and regular security updates.
	Regulatory Compliance: Stay updated with the latest regulations and compliance standards to avoid penalties or legal issues.

        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          4. Employee Self-Service Portal

        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Access to Information: Empower employees with a self-service portal to view pay stubs, access tax documents, and update personal information.   
	Reduced HR Queries: Minimize HR inquiries by enabling employees to manage their own payroll details.

        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          5. Integrated HR Management 
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        Seamless Integration: Integrate payroll seamlessly with other HR functions such as benefits management, attendance tracking, and on-boarding processes.   
	Data Insights: Generate comprehensive reports for informed decision-making on workforce management.


        </p>
       
        <img src={image2} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">Why Choose Us?</h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
        User-Friendly Interface: Navigate our intuitive platform effortlessly, making it easy for both HR administrators and employees.  
	Scalability and Growth: Grow your business without worrying about outgrowing the software; it evolves with your needs. 
	Dedicated Support: Benefit from our dedicated support team, offering training and assistance for a smooth transition and continued use
        </p>

        <h3 className="text-xl lg:text-xl font-desc">
        Ready to Transform Your HR Operations? Discover the efficiency and convenience of modern HR and payroll
management. <br />
<span className="font-bold">Contact us to schedule a demo or discuss how our software can revolutionize your HR processes!</span>

        </h3>
        <h3 className="text-xl lg:text-2xl font-semibold font-desc">
     

        </h3>
      </div>
      <Footer />
    </div>
  );
};

export default HRandPayroll;

import React, { useEffect } from "react";
import Navbar from "../NavBar";
import image1 from "../../assets/images/VisitingCard1.jpg";
import image2 from "../../assets/images/VisitingCard2.jpg";
import Footer from "../Footer";
import { Helmet } from "react-helmet";

const VisitingCard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar color={"black"} />
      <Helmet>
        <title>Visiting Card</title>
        <meta
          name="description"
          content="Create a lasting impression with our premium quality visiting card printing services."
        />
        <meta
          name="keywords"
          content="visiting card, business card, professional card, card printing"
        />
      </Helmet>
      <div className="container flex justify-center  flex-col gap-4 py-16 px-4 sm:px-6 lg:px-32">
        <div className="heading text-center">
          <h1 className="text-3xl lg:text-4xl font-bold text-Cdark">
            NFC Digital Visiting/Business Cards
          </h1>
        </div>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Introducing DigIT-Hack Card, a revolutionary smart digital visiting
          cum business card that allows you to exchange contact, collect data,
          and direct your potential customer, guest, or attendee to the info
          they need to know!
        </p>
        <img src={image1} alt="" className="mx-auto w-3/5" />
        <h2 className="text-xl lg:text-2xl font-semibold">
          What is NFC technology ?
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          Near-field communication refers to a set of wireless communication
          protocols that allows two smart phones to communicate within a few
          centimetres of each other.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          What is the DigIT-Hack Digital Visiting/Business Card and how does it
          work?
        </h2>
        <p className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
          DigIT-Hack card is a smart card with NFC (Near Field Communication)
          technology that allows you to share your contact information as well
          as product and service information with the tap of any NFC enabled
          smart phone. It is compatible with both IOS/Android smartphones. And,
          for Non-NFC smartphones, there will be a QR code to quickly share
          information.
        </p>
        <h2 className="text-xl lg:text-2xl font-semibold">
          Our Main Benefits:
        </h2>
        <ul className="list-disc lg:pl-3 pl-0">
          <li className="lg:text-lg text-sm md:text-sm text-Cdark font-desc">
            Share basic information like contact details, social media links
            etc.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            You can use it throughout as it’s a pretty strong card with easily
            editable format with unlimited scans.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            Share professional details like portfolio, business details, or any
            kind of website attachments.
          </li>
          <li className="lg:text-lg text-sm  md:text-sm text-Cdark font-desc">
            You can customize the card designs according to your
            company/individual requirement like logo, name of company, etc.
          </li>
        </ul>

        <img src={image2} alt="" className="mx-auto w-1/2" />
      </div>
      <Footer />
    </div>
  );
};

export default VisitingCard;

import React from "react";
import { useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigation = useNavigate();
  return (
    <div className="overflow-x-hidden h-screen w-screen">
      <div className=" flex justify-center items-center flex-col w-full h-full">
        <h1 className="text-4xl text-violet-500 font-bold">404 - Not Found</h1>
        <button
          className="bg-violet-500 px-6 py-3 text-xl text-white rounded-lg mt-4"
          onClick={() => navigation("/")}
        >
          Go to Home
        </button>
      </div>
    </div>
  );
};

export default NotFound;
